/* eslint-disable @next/next/no-img-element */
/*
 * @Author: 何泽颖 hezeying@autowise.ai
 * @Date: 2024-04-17 00:58:00
 * @LastEditors: 何泽颖 hezeying@autowise.ai
 * @LastEditTime: 2024-05-03 02:47:55
 * @FilePath: /mbti-web/app/components/WorksList/index.jsx
 * @Description: 作品列表
 */
'use client';
import defaultImage from '@/img/defaultImage.jpeg';
import isImageURL from '@/utils/isImageURL';
import styles from './index.module.scss';

const WorksList = (props) => {
  const { workList, name, click } = props;

  return (
    <div className={styles.worksList}>
      {workList.map((item) => {
        const regExp = new RegExp(name, 'g');
        const __html = item.name.replace(
          regExp,
          `<span style="color: #956E7D;">${name}</span>`
        );

        return (
          <div
            className={styles.worksList_item}
            key={item.id}
            onClick={() => {
              click(item.id);
            }}
          >
            <div className={styles.worksList_item_image}>
              {(item.users || []).map(({ img, id }, index) => (
                <div
                  key={id}
                  className={styles[`worksList_item_image_${index}`]}
                >
                  <img
                    src={isImageURL(img) ? img : defaultImage.src}
                    alt={id}
                  />
                </div>
              ))}
            </div>
            <div className={styles.worksList_item_content}>
              <div
                className={styles.worksList_item_content_name}
                dangerouslySetInnerHTML={{ __html }}
              />
              <div className={styles.worksList_item_content_enName}>
                {item.en_name}
              </div>
              <div className={styles.worksList_item_content_users}>
                {item.user_count > 0
                  ? `${item.users[0].zh_name} 等 ${item.user_count}位`
                  : ''}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default WorksList;
