/*
 * @Author: 何泽颖 hezeying@autowise.ai
 * @Date: 2024-04-26 19:10:39
 * @LastEditors: 何泽颖 hezeying@autowise.ai
 * @LastEditTime: 2024-04-26 21:11:37
 * @FilePath: /mbti-web/app/components/QrcodeModal/index.jsx
 * @Description:
 */
'use client';
import { Modal } from 'antd';
import Image from 'next/image';
import styles from './index.module.scss';

const QrcodeModal = (paops) => {
  const { isShowModalTitle, handleCancel, title, src } = paops;

  return (
    <>
      <Modal
        open={isShowModalTitle === title}
        onCancel={handleCancel}
        footer={null}
        width={400}
        closable={false}
        wrapClassName={styles.qrcodeModal}
      >
        <Image src={src} width={400} height={400} alt={'title'} />
      </Modal>
    </>
  );
};

export default QrcodeModal;
