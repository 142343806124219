/*
 * @Author: 何泽颖 hezeying@autowise.ai
 * @Date: 2024-02-20 10:09:23
 * @LastEditors: 何泽颖 hezeying@autowise.ai
 * @LastEditTime: 2024-06-07 11:32:40
 * @FilePath: /mbti-web/app/components/Footer/index.jsx
 * @Description:
 */
import styles from './index.module.scss';

function Footer() {
  return (
    <footer className={styles.footer}>
      <span>©wikimbti.com® all rights reserved</span>
      <a href="https://beian.miit.gov.cn">粤ICP备20065593号-4</a>
    </footer>
  );
}

export default Footer;
