/*
 * @Author: 何泽颖 hezeying@autowise.ai
 * @Date: 2024-02-20 10:09:23
 * @LastEditors: 何泽颖 hezeying@autowise.ai
 * @LastEditTime: 2024-04-17 00:46:07
 * @FilePath: /mbti-web/app/components/MoreBtn/index.jsx
 * @Description: 更多按钮
 */
'use client';
import { MoreIcon } from '@/icons'
import styles from './index.module.scss';

function MoreBtn(props) {
    const { onClick } = props;


    return (
        <div onClick={onClick} className={styles.moreBtn}>更多<MoreIcon /></div>
    );
}

export default MoreBtn;


