/*
 * @Author: 何泽颖 hezeying@autowise.ai
 * @Date: 2024-02-20 10:09:23
 * @LastEditors: 何泽颖 hezeying@autowise.ai
 * @LastEditTime: 2024-05-28 16:14:27
 * @FilePath: /mbti-web/app/theme/index.jsx
 * @Description:
 */
'use client';

import { ConfigProvider } from 'antd';

const withTheme = (node) => (
  <>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#AD96BC',
          colorTextPlaceholder: '#666666',
          colorLink: '#956e7d',
        },
        components: {
          Button: {
            defaultBg: '#F5F5F5',
          },
          Input: {
            paddingBlock: 6,
          },
          AutoComplete: {
            borderRadius: 10,
          },
        },
      }}
    >
      <ConfigProvider
        theme={{
          token: {
            borderRadius: 4,
          },
        }}
      >
        {node}
      </ConfigProvider>
    </ConfigProvider>
  </>
);

export default withTheme;
