/*
 * @Author: 何泽颖 hezeying@autowise.ai
 * @Date: 2024-04-16 20:01:30
 * @LastEditors: 何泽颖 hezeying@autowise.ai
 * @LastEditTime: 2024-04-25 22:07:44
 * @FilePath: /mbti-web/app/components/ProgressBar/index.jsx
 * @Description: 路由进度条组件
 */
'use client';
import { AppProgressBar as ProgressBar } from 'next-nprogress-bar';
const NprogressProvider = ({ children }) => {
  return (
    <>
      {children}
      <ProgressBar
        height="2px"
        color="#AD96BC"
        zIndex="9999999999"
        options={{ showSpinner: false }}
      />
    </>
  );
};
export default NprogressProvider;
