/*
 * @Author: 何泽颖 hezeying@autowise.ai
 * @Date: 2024-04-22 20:39:21
 * @LastEditors: 何泽颖 hezeying@autowise.ai
 * @LastEditTime: 2024-05-13 20:18:39
 * @FilePath: /mbti-web/app/components/ItemListT/index.jsx
 * @Description:
 */

'use client';
import defaultImage from '@/img/defaultImage.jpeg';
import isImageURL from '@/utils/isImageURL';
import { Skeleton } from 'antd';
import Image from 'next/image';
import styles from './index.module.scss';

const ItemListT = (props) => {
  const { itemList, name, click, loading } = props;

  return (
    <div className={styles.itemsList}>
      {!loading &&
        (itemList || []).map((item) => {
          const regExp = new RegExp(name, 'g');
          const __html = item.name
            ? item.name.replace(
                regExp,
                `<span style="color: #956E7D;">${name}</span>`
              )
            : item.mbti_profile.replace(
                regExp,
                `<span style="color: #956E7D;">${name}</span>`
              );

          return (
            <div
              className={styles.itemsList_item}
              key={item.id}
              onClick={() => {
                click(item.id);
              }}
            >
              <div className={styles.itemsList_item_content}>
                <div className={styles.itemsList_item_content_image}>
                  <Image
                    alt={item.mbti_profile}
                    src={isImageURL(item.img) ? item.img : defaultImage.src}
                    width={64}
                    height={64}
                    style={{ borderRadius: 8 }}
                  />
                </div>
                <div className={styles.itemsList_item_content_details}>
                  <div
                    className={styles.itemsList_item_content_details_name}
                    dangerouslySetInnerHTML={{ __html }}
                  />
                  <div
                    className={
                      styles.itemsList_item_content_details_mbtiProfile
                    }
                  >
                    {item.mbti_profile}
                  </div>
                  <div className={styles.itemsList_item_content_details_works}>
                    {item.works}
                  </div>
                </div>
              </div>
              <div className={styles.itemsList_item_mbti}>
                <div className={styles.itemsList_item_mbti_topicName}>
                  {item.topic_name}
                </div>
                <div className={styles.itemsList_item_mbti_enneagramType}>
                  {item.enneagram_type}
                </div>
              </div>
            </div>
          );
        })}

      {/* 骨架屏 */}
      {loading &&
        [...Array(10).keys()].map((i) => (
          <div className={styles.itemsList_skeleton} key={i}>
            <div className={styles.itemsList_skeleton_content}>
              <Skeleton.Avatar active shape="square" />
              <div className={styles.itemsList_skeleton_content_details}>
                <Skeleton.Input size="small" active className={styles.name} />
                <Skeleton.Input active className={styles.mbtiProfile} />
                <Skeleton.Input active className={styles.works} />
              </div>
            </div>

            <div className={styles.itemsList_skeleton_mbti}>
              <Skeleton.Button size="small" active />
              <Skeleton.Button size="small" active />
            </div>
          </div>
        ))}
    </div>
  );
};

export default ItemListT;
