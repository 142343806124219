/*
 * @Author: 何泽颖 hezeying@autowise.ai
 * @Date: 2024-02-20 10:09:23
 * @LastEditors: 何泽颖 hezeying@autowise.ai
 * @LastEditTime: 2024-05-14 13:38:46
 * @FilePath: /mbti-web/app/components/HomeContent/index.jsx
 * @Description:MBTI选择器
 */
import { MBTI_OCTUPLES } from '@/config';
import { ConfigProvider, Select } from 'antd';
import styles from './index.module.scss';

const CharacterSelect = ({ character, onChange }) => (
  <div className={styles.characterSelect}>
    <ConfigProvider
      theme={{
        components: {
          Select: {
            optionPadding: '5px 20px',
          },
        },
      }}
    >
      <Select
        value={character}
        style={{ width: 100 }}
        placeholder="选择MBTI"
        suffixIcon={null}
        size="large"
        options={MBTI_OCTUPLES}
        onChange={onChange}
      />
    </ConfigProvider>
  </div>
);

export default CharacterSelect;
