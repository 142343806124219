/*
 * @Author: 何泽颖 hezeying@autowise.ai
 * @Date: 2024-02-20 10:09:23
 * @LastEditors: 何泽颖 hezeying@autowise.ai
 * @LastEditTime: 2024-05-15 13:08:22
 * @FilePath: /mbti-web/app/components/HomeContent/index.jsx
 * @Description:首页主要内容
 */

'use client';
import { CharacterSelect, ItemListT, Pagination } from '@/components';
import { CLASSIFY_LIST } from '@/config';
import useRouter from '@/hooks/useRouter';
import { getList as getListAsync } from '@/service';
import withTheme from '@/theme';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';

const PAGESIZE = 10;

function HomeContent() {
  const [character, setCharactert] = useState(null); // 当前选中的性格
  const [selWorkId, setSelWorkId] = useState(null); // 选中的作品id
  const [itemList, setItemList] = useState([]); // 人物列表
  const [itemListCurrent, setItemListCurrent] = useState(1); // 人物列表当前页
  const [itemListTotal, setItemListTotal] = useState(0); // 人物列表总数
  const [loading, setLoading] = useState(true);

  const router = useRouter();

  useEffect(() => {
    searcWorkItems({
      type: 21,
      page: 1,
      limit: PAGESIZE,
      mbti: '',
    });
  }, []);

  // // 图片预加载
  // const imgOnload = (imgUrl) =>
  //   new Promise((resolve, reject) => {
  //     console.log(isImageURL(imgUrl));
  //     const img = new Image();
  //     const normalUrl = `${imgUrl}?w=64&q=75`; // 正常合法照片Url
  //     const defaultUrl = `${defaultImage.src}?w=64&q=75`; // 缺省图照片Url
  //     img.src = isImageURL(imgUrl) ? normalUrl : defaultUrl;
  //     img.onload = () => resolve();
  //     img.onerror = () => reject('加载失败');
  //   });

  // 通过作品搜索人物
  const searcWorkItems = async (params) => {
    setSelWorkId(params.type);
    setItemListCurrent(params.page);
    const res = await getListAsync(params);
    setItemList(res?.data || []);
    setItemListTotal(res?.count || 0);
    setLoading(false);
  };

  /** 组件 */
  const ClassAsideTitle = ({ text, mb }) => (
    <div className={styles.title} style={{ marginBottom: mb }}>
      {text}
    </div>
  );

  const ClassifyList = () => (
    <div className={styles.classifyList}>
      {CLASSIFY_LIST.map(({ label, id }) => (
        <div
          className={`${styles.classifyList_item} ${selWorkId === id ? styles.classifyList_selectdItem : ''}`}
          key={id}
          onClick={() => {
            searcWorkItems({
              type: id,
              page: 1,
              limit: PAGESIZE,
              mbti: character || '',
            });
          }}
        >
          {label}
        </div>
      ))}
    </div>
  );

  const VerticalLine = () => <div className={styles.verticalLine} />;

  return (
    <div className={styles.homeContent}>
      <aside className={styles.homeContent_classAside}>
        <ClassAsideTitle text="按性格" mb={12} />
        <CharacterSelect
          value={character}
          onChange={(value) => {
            setCharactert(value || null);
            searcWorkItems({
              type: selWorkId,
              page: 1,
              limit: PAGESIZE,
              mbti: value || '',
            });
          }}
        />
        <ClassAsideTitle text="按分类" mb={16} />
        <ClassifyList />
      </aside>

      <VerticalLine />

      <section className={styles.homeContent_itemList}>
        <ItemListT
          itemList={itemList}
          name={''}
          click={(id) => {
            router.push(`/pediat?id=${id}`);
          }}
          loading={loading}
        />
        <Pagination
          current={itemListCurrent}
          total={itemListTotal}
          pageSize={PAGESIZE}
          showSizeChanger={false}
          onChange={(page) => {
            searcWorkItems({
              type: selWorkId,
              page,
              limit: PAGESIZE,
              mbti: character || '',
            });
          }}
        />
      </section>
    </div>
  );
}

const HomeContentCom = () => {
  return withTheme(<HomeContent />);
};

export default HomeContentCom;
