/*
 * @Author: 何泽颖 hezeying@autowise.ai
 * @Date: 2024-02-20 10:09:23
 * @LastEditors: 何泽颖 hezeying@autowise.ai
 * @LastEditTime: 2024-04-30 22:34:01
 * @FilePath: /mbti-web/app/components/Header/index.jsx
 * @Description:
 */
'use client';

import Search from '@/components/Search';
import useRouter from '@/hooks/useRouter';
import AppQrcode from '@/img/app_qrcode.jpeg';
import TestLogoPng from '@/img/mbtitest/logo.png';
import WeappQrcode from '@/img/weapp_qrcode.jpeg';
import withTheme from '@/theme';
import { Button, ConfigProvider, Popover } from 'antd';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import styles from './index.module.scss';

function Header() {
  // const SearchInputSuffix = () => (
  //   <span
  //     style={{
  //       color: '#564266',
  //     }
  //   >
  //     网页搜索开发中，暂不可用，请先使用手机搜索 →
  //   </span>
  // );
  const pathname = usePathname();

  const router = useRouter();

  return (
    <header className={styles.header}>
      <div className={styles.header_content}>
        {pathname !== '/mbtitest' && (
          <div
            className={styles.header_content_logo}
            onClick={() => {
              router.push('/');
            }}
          >
            <Image src="/logo.png" width={32} height={32} alt="mbti百科logo" />
            <div className={styles.header_content_logo_text}>
              <span>MBTI百科</span>
              {/* <span>查询名人的MBTI</span> */}
            </div>
          </div>
        )}
        {pathname === '/mbtitest' && (
          <div className={styles.header_content_testLogo}>
            <Image
              src={TestLogoPng}
              width={32}
              height={32}
              alt="mbti百科logo"
            />
            <div className={styles.header_content_testLogo_text}>
              <span>MBTI测试八维</span>
            </div>
          </div>
        )}

        {pathname !== '/mbtitest' && <Search />}

        <div className={styles.header_content_placeholder} />

        {pathname !== '/mbtitest' && (
          <div className={styles.header_content_btn}>
            <Popover
              placement="bottom"
              content={
                <Image
                  src={AppQrcode.src}
                  width={220}
                  height={220}
                  alt="AppQrcode"
                />
              }
            >
              <Button size="large" style={{ marginRight: '16px' }}>
                App下载
              </Button>
            </Popover>

            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: '#333333 ',
                },
              }}
            >
              <Popover
                placement="bottom"
                content={
                  <Image
                    src={WeappQrcode.src}
                    width={220}
                    height={220}
                    alt="WeappQrcode"
                  />
                }
              >
                <Button type="primary" size="large">
                  微信小程序
                </Button>
              </Popover>
            </ConfigProvider>
          </div>
        )}
      </div>
    </header>
  );
}

const HeaderPage = () => {
  return withTheme(<Header />);
};

export default HeaderPage;
