/*
 * @Author: 何泽颖 hezeying@autowise.ai
 * @Date: 2024-04-26 19:37:06
 * @LastEditors: 何泽颖 hezeying@autowise.ai
 * @LastEditTime: 2024-05-01 02:19:09
 * @FilePath: /mbti-web/app/components/ProductT/index.jsx
 * @Description:工具栏
 */
'use client';
import H5Modal from '@/components/H5Modal';
import QrcodeModal from '@/components/QrcodeModal';
import { PRODUCT_LIST } from '@/config';
import TestQrcode from '@/img/test_qrcode.jpg';
import Image from 'next/image';
import { useState } from 'react';
import styles from './index.module.scss';

export default function ProductT() {
  const [isShowModalTitle, setIsShowModalTitle] = useState('');

  const handleCancel = () => {
    setIsShowModalTitle('');
  };

  return (
    <div className={styles.productT}>
      <div className={styles.productT_title}>工具库</div>

      <div className={styles.productT_list}>
        {PRODUCT_LIST.map(({ img, title, subhead }) => (
          <div
            key={title}
            className={styles.productT_list_item}
            onClick={() => {
              setIsShowModalTitle(title);
            }}
          >
            <Image src={img} width={38} height={38} alt={title} />
            <div className={styles.productT_list_item_text}>
              <span>{title}</span>
              <span>{subhead}</span>
            </div>
          </div>
        ))}
      </div>

      <H5Modal
        isShowModalTitle={isShowModalTitle}
        title="八维功能"
        src="https://match.wikimbti.com/#/pages/index/index?type=explain&mbti=XXXX"
        handleCancel={handleCancel}
      />

      <H5Modal
        isShowModalTitle={isShowModalTitle}
        title="关系图鉴"
        src="https://match.wikimbti.com"
        handleCancel={handleCancel}
      />

      <H5Modal
        isShowModalTitle={isShowModalTitle}
        title="性格类型"
        src="https://test.wikimbti.com/?from=watch#/pages/result/index?mbti=ENTP"
        handleCancel={handleCancel}
      />

      <H5Modal
        isShowModalTitle={isShowModalTitle}
        title="八维转换器"
        src="https://match.wikimbti.com/calculator"
        handleCancel={handleCancel}
      />

      <QrcodeModal
        isShowModalTitle={isShowModalTitle}
        title="测MBTI"
        src={TestQrcode.src}
        handleCancel={handleCancel}
      />
    </div>
  );
}
