/*
 * @Author: 何泽颖 hezeying@autowise.ai
 * @Date: 2024-04-26 19:10:39
 * @LastEditors: 何泽颖 hezeying@autowise.ai
 * @LastEditTime: 2024-05-13 20:06:47
 * @FilePath: /mbti-web/app/components/H5Modal/index.jsx
 * @Description:
 */
'use client';
import { Modal } from 'antd';
import styles from './index.module.scss';

const H5Modal = (paops) => {
  const { isShowModalTitle, handleCancel, title, src } = paops;

  return (
    <>
      <Modal
        open={isShowModalTitle === title}
        onCancel={handleCancel}
        footer={null}
        width={375}
        closable={false}
        wrapClassName={styles.h5Modal}
      >
        <iframe
          src={src}
          width="375px"
          height="667px"
          frameBorder="0"
          allowFullScreen
        />
      </Modal>
    </>
  );
};

export default H5Modal;
