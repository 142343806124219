/*
 * @Author: 何泽颖 hezeying@autowise.ai
 * @Date: 2024-02-20 10:09:23
 * @LastEditors: 何泽颖 hezeying@autowise.ai
 * @LastEditTime: 2024-05-01 03:13:20
 * @FilePath: /mbti-web/app/components/Pagination/index.jsx
 * @Description:
 */
'use client';
import withTheme from '@/theme';
import { Pagination } from 'antd';
import styles from './index.module.scss';

function MyPagination(props) {
  return (
    <div className={styles.pagination}>
      <Pagination {...props} />
    </div>
  );
}

const MyPaginationCom = (props) => {
  return withTheme(<MyPagination {...props} />);
};

export default MyPaginationCom;
