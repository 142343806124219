/* eslint-disable react-hooks/exhaustive-deps */
/*
 * @Author: 何泽颖 hezeying@autowise.ai
 * @Date: 2024-02-20 10:09:23
 * @LastEditors: 何泽颖 hezeying@autowise.ai
 * @LastEditTime: 2024-05-03 03:34:04
 * @FilePath: /mbti-web/app/components/Search/index.jsx
 * @Description:
 */
'use client';
import ItemList from '@/components/ItemList';
import MoreBtn from '@/components/MoreBtn';
import Pagination from '@/components/Pagination';
import WorksList from '@/components/WorksList';
import {
  ITEM_SEARCH_OPTION,
  MBTI_OCTUPLE_LIST,
  SEARCH_OPTION,
  WORK_SEARCH_OPTION,
} from '@/config';
import useRouter from '@/hooks/useRouter';
import { ClearIcon, SearchIcon } from '@/icons';
import {
  iselWorkIdAtom,
  itemListAtom,
  itemListCurrentAtom,
  itemListTotalAtom,
  nameAtom,
  openAtom,
  tabKeyAtom,
  tierAtom,
  towItemListAtom,
  towItemListCurrentAtom,
  towItemListTotalAtom,
  workListAtom,
  workListCurrentAtom,
  workListTotalAtom,
} from '@/models/search';
import {
  getList as getListAsync,
  searchItem as searchItemAsync,
  searchWorks as searchWorksAsync,
} from '@/service';
import withTheme from '@/theme';
import { useDebounceFn } from 'ahooks';
import { AutoComplete, Button, ConfigProvider, message } from 'antd';
import { useAtom } from 'jotai';
import { usePathname } from 'next/navigation';
import { useEffect, useMemo } from 'react';
import styles from './index.module.scss';

function Search() {
  const [name, setName] = useAtom(nameAtom); // 当前输入框内容
  const [open, setOpen] = useAtom(openAtom); // 是否展开下拉菜单
  const [tabKey, setTabKey] = useAtom(tabKeyAtom); // 当前tab选项
  const [tier, setTier] = useAtom(tierAtom); // 当前层级

  const [workList, setWorkList] = useAtom(workListAtom); // 作品列表
  const [workListCurrent, setWorkListCurrent] = useAtom(workListCurrentAtom); // 作品列表当前页
  const [workListTotal, setWorkListTotal] = useAtom(workListTotalAtom); // 作品列表总数

  const [itemList, setItemList] = useAtom(itemListAtom); // 人物列表
  const [itemListCurrent, setItemListCurrent] = useAtom(itemListCurrentAtom); // 人物列表当前页
  const [itemListTotal, setItemListTotal] = useAtom(itemListTotalAtom); // 人物列表总数

  const [towItemList, setTowItemList] = useAtom(towItemListAtom); // 第二层人物列表
  const [towItemListCurrent, setTowItemListCurrent] = useAtom(
    towItemListCurrentAtom
  ); // 第二层人物列表当前页
  const [towItemListTotal, setTowItemListTotal] = useAtom(towItemListTotalAtom); // 第二层人物列表总数

  const [selWorkId, setSelWorkId] = useAtom(iselWorkIdAtom); // 选中的作品id

  const pathname = usePathname();
  const router = useRouter();

  const searchOption = useMemo(() => {
    if (itemList.length !== 0 && workList.length !== 0) return SEARCH_OPTION;

    if (itemList.length !== 0 && workList.length === 0)
      return ITEM_SEARCH_OPTION;

    if (itemList.length === 0 && workList.length !== 0)
      return WORK_SEARCH_OPTION;

    return [];
  }, [workList, itemList]);

  // 搜索人物
  const searchItem = async (params) => {
    // 判断是否搜索八维，修改传参
    if (MBTI_OCTUPLE_LIST.includes(params.name?.slice(0, 4).toUpperCase())) {
      if (
        !(
          params.name.length === 7 &&
          typeof Number(params.name[4]) === 'number' &&
          typeof Number(params.name[6]) === 'number' &&
          params.name[5].toUpperCase() === 'W'
        )
      ) {
        params.name = params.name?.slice(0, 4);
      }
    }

    const res = await searchItemAsync(params);

    setItemList(res?.data || []);
    setItemListTotal(res?.count || 0);
  };

  // 搜索作品
  const searchWorks = async (params) => {
    const res = await searchWorksAsync(params);
    setWorkList(res?.data || []);
    setWorkListTotal(res?.count || 0);
  };

  // 通过作品搜索人物
  const searcWorkItems = async (params) => {
    setTier(1);
    setSelWorkId(params.work_id);
    const res = await getListAsync(params);
    setTowItemList(res?.data || []);
    setTowItemListTotal(res?.count || 0);
  };

  // 搜索
  const { run: searchNameChange } = useDebounceFn(
    (name, page) => {
      if (name) {
        searchItem({ page, name, limit: 6 });
        searchWorks({ page, name, limit: 6 });
      }
    },
    {
      wait: 300,
    }
  );

  // 状态重置
  const stateReset = () => {
    setTier(0);
    setTabKey(0);
    setWorkList([]);
    setItemList([]);
    setTowItemList([]);
    setSelWorkId(null);
  };

  const CuttingLine = () => <div className={styles.cuttingLine} />;

  const Tabs = () => (
    <div className={styles.tags}>
      {searchOption.map(({ label, value }) => (
        <div
          key={value}
          onClick={() => {
            setTabKey(value);
          }}
          className={`${styles.tags_item} ${tabKey === value ? styles.tags_selItem : ''}`}
        >
          {label}
        </div>
      ))}
    </div>
  );

  const TabAll = () => (
    <div className={styles.all}>
      {workList.length !== 0 && (
        <div className={styles.all_work}>
          <div className={styles.header}>
            <div className={styles.header_name}>作品</div>
            <MoreBtn
              onClick={() => {
                setTabKey(2);
              }}
            />
          </div>
          <WorksList
            workList={workList.slice(0, 2)}
            name={name}
            click={(work_id) => {
              searcWorkItems({
                work_id,
                page: 1,
                limit: 6,
              });
            }}
          />
        </div>
      )}
      {itemList.length !== 0 && (
        <div className={styles.all_item}>
          <div className={styles.header}>
            <div className={styles.header_name}>人物</div>
            <MoreBtn
              onClick={() => {
                setTabKey(1);
              }}
            />
          </div>
          <ItemList
            itemList={itemList.slice(0, 3)}
            name={name}
            click={(id) => {
              setName('');
              stateReset();

              router.push(`/pediat?id=${id}`);
            }}
          />
        </div>
      )}
    </div>
  );

  const TabItem = () => (
    <div className={styles.item}>
      <ItemList
        itemList={itemList}
        name={name}
        click={(id) => {
          setName('');
          stateReset();
          router.push(`/pediat?id=${id}`);
        }}
      />

      <Pagination
        current={itemListCurrent}
        total={itemListTotal}
        pageSize={6}
        showSizeChanger={false}
        onChange={(page) => {
          searchItem({ page, name, limit: 6 });
          setItemListCurrent(page);
        }}
      />
    </div>
  );

  const TabWork = () => (
    <div className={styles.work}>
      <WorksList
        workList={workList}
        name={name}
        click={(work_id) => {
          searcWorkItems({
            work_id,
            page: 1,
            limit: 6,
          });
        }}
      />

      <Pagination
        current={workListCurrent}
        total={workListTotal}
        pageSize={6}
        showSizeChanger={false}
        onChange={(page) => {
          searchWorks({ page, name, limit: 6 });
          setWorkListCurrent(page);
        }}
      />
    </div>
  );

  const TowTier = () => (
    <div className={styles.towTier}>
      <div className={styles.towTier_header}>
        <Button
          type="link"
          onClick={() => {
            setTier(0);
            setTowItemListCurrent(1);
          }}
        >
          返回上一级
        </Button>
        <div className={styles.towTier_header_workName}>
          {workList.find((item) => item.id === selWorkId)?.name}
        </div>
      </div>
      <div className={styles.cuttingLine} />
      <div className={styles.towTier_items}>
        <ItemList
          itemList={towItemList}
          name={name}
          click={(id) => {
            setName('');
            stateReset();
            router.push(`/pediat?id=${id}`);
          }}
        />

        <Pagination
          current={towItemListCurrent}
          total={towItemListTotal}
          pageSize={6}
          showSizeChanger={false}
          onChange={(page) => {
            searcWorkItems({
              work_id: selWorkId,
              page,
              limit: 6,
            });
            setTowItemListCurrent(page);
          }}
        />
      </div>
    </div>
  );

  // 是否展开选项
  useEffect(() => {
    setOpen(!(itemList.length === 0 && workList.length === 0));
  }, [itemList, workList]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      if (
        itemList.length === 0 &&
        workList.length === 0 &&
        name.trim() !== ''
      ) {
        message.info({
          content: '暂无结果',
          style: { margin: '20vh' },
        });
      }
    }
  };

  // 回车监听
  useEffect(() => {
    // 添加键盘事件监听器
    window.addEventListener('keydown', handleKeyDown);

    // 清除键盘事件监听器
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [itemList, workList, name]);

  return (
    <div
      className={`${pathname === '/' ? styles.homesearch : styles.pagesearch} ${styles.search}`}
    >
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#999999',
            borderRadius: 10,
            controlHeightLG: 48,
            paddingSM: 44,
            colorBgContainer: '#f5f5f5',
            colorBorder: '#DDDDDD',
          },
        }}
      >
        <AutoComplete
          open={open}
          value={name}
          style={{ width: '540px' }}
          size="large"
          options={[{ label: '', value: '' }]}
          placeholder="搜索名人、作品、MBTI等"
          getPopupContainer={(trigger) => trigger.parentNode}
          dropdownRender={() => (
            <div className={styles.search_render}>
              {tier === 0 && <Tabs />}
              {tier === 0 && <CuttingLine />}
              {tier === 0 && tabKey === 0 && <TabAll />}
              {tier === 0 && tabKey === 1 && <TabItem />}
              {tier === 0 && tabKey === 2 && <TabWork />}
              {tier === 1 && <TowTier />}
            </div>
          )}
          onChange={(name) => {
            name = name || '';
            setName(name);
            searchNameChange(name.trim(), 1);
            setItemListCurrent(1);
            setWorkListCurrent(1);
            if (name.trim() === '') stateReset();
          }}
        />
      </ConfigProvider>

      <div className={styles.search_icon}>
        <SearchIcon />
      </div>

      <div className={styles.clear_icon}>
        {name && (
          <Button
            type="text"
            icon={<ClearIcon />}
            onClick={() => {
              setName('');
              stateReset();
            }}
          />
        )}
      </div>
    </div>
  );
}

const SearchCom = () => {
  return withTheme(<Search />);
};

export default SearchCom;
