import GoogleAnalytics from '@/components/GoogleAnalytics'; // 谷歌埋点
import Header from '@/components/Header'; // 网页头部
import HomeContent from '@/components/HomeContent'; // 首页主要内容
import HotLinks from '@/components/HotLinks'; // 热门搜索按钮
import ProductT from '@/components/ProductT'; // 新版工具栏
import ProgressBar from '@/components/ProgressBar'; // 路由加载条
import TtzzScript from '@/components/TtzzScript'; // 字节SEO
import Footer from '@/components/Footer'; // 网页底部
import CharacterSelect from '@/components/CharacterSelect'; // MBTI选择器
import ItemListT from '@/components/ItemListT'; // 人物列表2
import Pagination from '@/components/Pagination'; // 分页器

export {
  GoogleAnalytics,
  Header,
  HomeContent,
  HotLinks,
  ProductT,
  ProgressBar,
  TtzzScript,
  Footer,
  CharacterSelect,
  ItemListT,
  Pagination,
};
