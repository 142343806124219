/*
 * @Author: 何泽颖 hezeying@autowise.ai
 * @Date: 2024-04-22 20:39:21
 * @LastEditors: 何泽颖 hezeying@autowise.ai
 * @LastEditTime: 2024-05-06 15:56:14
 * @FilePath: /mbti-web/app/components/ItemList/index.jsx
 * @Description:
 */

'use client';
import defaultImage from '@/img/defaultImage.jpeg';
import isImageURL from '@/utils/isImageURL';
import Image from 'next/image';
import styles from './index.module.scss';

const ItemList = (props) => {
  const { itemList, name, click } = props;

  return (
    <div className={styles.itemsList}>
      {(itemList || []).map((item) => {
        const regExp = new RegExp(name, 'g');
        const __html = item.name
          ? item.name.replace(
              regExp,
              `<span style="color: #956E7D;">${name}</span>`
            )
          : item.mbti_profile.replace(
              regExp,
              `<span style="color: #956E7D;">${name}</span>`
            );

        return (
          <div
            className={styles.itemsList_item}
            key={item.id}
            onClick={() => {
              click(item.id);
            }}
          >
            <div className={styles.itemsList_item_content}>
              <div className={styles.itemsList_item_content_image}>
                <Image
                  alt={item.mbti_profile}
                  src={isImageURL(item.img) ? item.img : defaultImage.src}
                  width={64}
                  height={64}
                  style={{ borderRadius: 8 }}
                />
              </div>
              <div className={styles.itemsList_item_content_details}>
                <div
                  className={styles.itemsList_item_content_details_name}
                  dangerouslySetInnerHTML={{ __html }}
                />
                <div
                  className={styles.itemsList_item_content_details_mbtiProfile}
                >
                  {item.mbti_profile}
                </div>
                <div className={styles.itemsList_item_content_details_works}>
                  {item.works}
                </div>
              </div>
            </div>
            <div className={styles.itemsList_item_mbti}>
              <div className={styles.itemsList_item_mbti_topicName}>
                {item.topic_name}
              </div>
              <div className={styles.itemsList_item_mbti_enneagramType}>
                {item.enneagram_type}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ItemList;
