/* eslint-disable @next/next/inline-script-id */
/*
 * @Author: 何泽颖 hezeying@autowise.ai
 * @Date: 2024-04-10 16:24:34
 * @LastEditors: 何泽颖 hezeying@autowise.ai
 * @LastEditTime: 2024-05-13 20:01:51
 * @FilePath: /mbti-web/app/components/TtzzScript.jsx
 * @Description:头条seo收录
 */
'use client';
import Script from 'next/script';

const TtzzScript = () => {
  return (
    <>
      <Script
        id="ttzz"
        src="https://lf1-cdn-tos.bytegoofy.com/goofy/ttzz/push.js?d2e4585e4b8f0f6208b9a080cfa5aeca096acfa984756737fbd7c86af9850924bc434964556b7d7129e9b750ed197d397efd7b0c6c715c1701396e1af40cec962b8d7c8c6655c9b00211740aa8a98e2e"
        strategy="beforeInteractive"
      />
    </>
  );
};

export default TtzzScript;
