/*
 * @Author: 何泽颖 hezeying@autowise.ai
 * @Date: 2024-04-01 19:17:07
 * @LastEditors: 何泽颖 hezeying@autowise.ai
 * @LastEditTime: 2024-04-29 19:14:37
 * @FilePath: /mbti-web/app/components/GoogleAnalytics.jsx
 * @Description:
 */
'use client';
import Script from 'next/script';

const GoogleAnalytics = () => {
  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=G-VQBXDD102X`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-VQBXDD102X', {
            page_path: window.location.pathname,
            });
          `,
        }}
      />
    </>
  );
};

export default GoogleAnalytics;
