/*
 * @Author: 何泽颖 hezeying@autowise.ai
 * @Date: 2024-02-20 10:09:23
 * @LastEditors: 何泽颖 hezeying@autowise.ai
 * @LastEditTime: 2024-05-01 03:02:23
 * @FilePath: /mbti-web/app/components/HotLinks/index.jsx
 * @Description:
 */
'use client';

import { HOT_SEARCH_LIST } from '@/config';
import useRouter from '@/hooks/useRouter';
import withTheme from '@/theme';
import { Button } from 'antd';
import styles from './index.module.scss';

function HotLinks() {
  const router = useRouter();

  return (
    <div className={styles.hotLinks}>
      {HOT_SEARCH_LIST.map(({ name, id, type }) => (
        <Button
          type="link"
          key={name + id}
          size="large"
          onClick={() => {
            console.log(type);
            if (['mbti', 'work'].includes(type)) {
              router.push(`/workt?id=${id}&name=${name}`);
            } else {
              router.push(`/pediat?id=${id}`);
            }
          }}
        >
          {name}
        </Button>
      ))}
    </div>
  );
}

const HotLinksCom = () => {
  return withTheme(<HotLinks />);
};

export default HotLinksCom;
